/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-globals */

import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import {
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { IonCard, IonCardContent, IonContent, IonPage } from "@ionic/react";
import DocumentMeta from "react-document-meta";

import "./Yappa.css";
//import { ComentariosPuntuacion } from "../../componentes/comentariosYPuntuacion/ComentarioPuntuacion";
import { BtnCanjearYappa } from "../../componentes/botonCanjear/BTNCanjearYappa";
import { BtnsLikeShare } from "../../componentes/botonesLikeShare/BtnsLikeShare";

import RedireccionarLogin from "../../funcs/RedireccionarLogin";
import YappasRelacionadas from "../../componentes/yappaRelacionadas/YappasRelacionadas";
import { BtnBack } from "../../componentes/btnBack/BtnBack";
import MenuOver from "../../componentes/header/MenuOver";

import Sliders from "../../componentes/Sliders/Sliders";

export const Yappa = () => {
  const [presentAlert] = useIonAlert();
  const slug = useParams();
  const [yappa, setYappa] = useState<any[]>([]);
  const [subcategoria, setSubcategoria] = useState<String>("");
  const [id, setId] = useState<any>({});
  const [comentario, setComentario] = useState<any[]>([]);
  const [yappaRelacionada, setyappaRelacionada] = useState<any[]>([]);
  const history = useHistory();
  const [icono, setIcono] = useState(
    "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/9fc0ed25-63ad-4365-d2e4-6739bc7f2c00/public"
  );

  const meta = {
    title: yappa[0]?.nombre,
    description: yappa[0]?.descripcion,
    canonical:
      "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/c8a56f68-9109-4d68-831f-f4d236b75200/public",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "yappa, meta, document, html, tags",
      },
      property: {
        "og:image":
          "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/c8a56f68-9109-4d68-831f-f4d236b75200/public",
        "og:title": yappa[0]?.nombre,
        "og:description": yappa[0]?.descripcion,
        "og:url":
          "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/c8a56f68-9109-4d68-831f-f4d236b75200/public",
      },
    },
  };

  const obtener_imagen_categoria = async (id_subcategoria: any) => {
    const body = {
      id_subcategoria: id_subcategoria,
    };

    const response = await fetch(
      "https://mi.yappa.ec/api/subcategoria_por_id",
      {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );

    const data = await response.json();
    setIcono(data[0].url_img);
  };

  const validarSesion = () => {
    return sessionStorage.getItem("id") != null &&
      sessionStorage.getItem("id") !== "null"
      ? true
      : false;
  };

  const modal = () => {
    if (validarSesion()) {
      presentAlert({
        header: "¿Qué te pareció esta Yappa?",
        buttons: [
          {
            text: "Valorar",
            handler: async (alertData) => {
              await valorar(alertData.puntaje);
              await comentar(alertData.comentario);
            },
          },
          "Ahora no",
        ],
        inputs: [
          {
            name: "puntaje",
            type: "number",
            placeholder: "Puntucación del 1 al 5",
            min: 1,
            max: 5,
            value: 1,
          },
          { name: "comentario", placeholder: "Comentario" },
        ],
      });
    } else {
      RedireccionarLogin(
        presentAlert,
        history,
        "Recuerda que para usar tu yappa debes tener una cuenta."
      );
    }
  };

  const valorar = async (puntaje: any) => {
    if (validarSesion()) {
      // eslint-disable-next-line no-restricted-globals
      const id_yappa = location.href.split("/");

      const body = {
        id_usuario: +sessionStorage.getItem("id")!,
        id_yappa: +id_yappa.at(-1)!,
        puntaje: puntaje,
      };

      const response = await fetch("https://mi.yappa.ec/api/valorar_yappa", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      });

      const data = await response.json();

      if (await data.original.original.mensaje) {
        presentAlert({
          header: data.original.original.mensaje,
        });
      }
    } else {
      RedireccionarLogin(
        presentAlert,
        history,
        "Recuerda que para usar tu yappa debes tener una cuenta."
      );
    }
  };

  const comentar = async (comentario: any) => {
    if (validarSesion()) {
      // eslint-disable-next-line no-restricted-globals
      const id_yappa = location.href.split("/");

      const body = {
        id_usuario: +sessionStorage.getItem("id")!,
        id_yappa: +id_yappa.at(-1)!,
        comentario: comentario,
      };

      const response = await fetch("https://mi.yappa.ec/api/comentar_yappa", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      });

      const data = await response.json();

      if (await data.original.original.mensaje) {
        presentAlert({
          header: data.original.original.mensaje,
        });
      }
    } else {
      RedireccionarLogin(
        presentAlert,
        history,
        "Recuerda que para usar tu yappa debes tener una cuenta."
      );
    }
  };

  const comentarios = async () => {
    // eslint-disable-next-line no-restricted-globals
    const id_yappa = location.href.split("/");

    const body = {
      id: +id_yappa.at(-1)!,
    };

    const response = await fetch(
      "https://mi.yappa.ec/api/comentario_de_una_yappa",
      {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );

    const data = await response.json();
    setComentario(data);
  };

  const obtener_id_yappa = () => {
    // eslint-disable-next-line no-restricted-globals
    let nombre_yappa: any = location.href.split("/");
    nombre_yappa = nombre_yappa.at(-1);
    nombre_yappa = nombre_yappa.replaceAll("-", " ")
    return decodeURI(nombre_yappa);
  };

  const obtener_yappa_relacionada = async () => {
    const body = {
      nombre_yappa: obtener_id_yappa(),
    };

    const response = await fetch("https://mi.yappa.ec/api/yappa_relacionadas", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    });
    const data = await response.json();
    setyappaRelacionada(data);
  };

  const obtainSubcategory = (value: any) => {
    let subcategoria = "";
    if (value.search(/Viajes/) >= 0) {
      subcategoria = value.replaceAll(" / ", "-");
    }

    return subcategoria;
  };

  useEffect(() => {
    const getYappaInfo = async (id: any) => {
      let nombreYappa = id.id.replaceAll("-", " ");
      const response = await fetch(
        "https://mi.yappa.ec/api/yappa_informacion_por_nombre",
        {
          method: "POST",
          body: JSON.stringify({ nombre: decodeURI(nombreYappa) }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const data = await response.json();

      setYappa(data);
      setSubcategoria(obtainSubcategory(await data[0].subcategoria));
      obtener_imagen_categoria(data[0]?.id_subcategoria);
    };

    getYappaInfo(slug);
    setId(slug);
  }, [slug]);

  useEffect(() => {
    comentarios();
    obtener_yappa_relacionada();
  }, []);

  return (
    <DocumentMeta {...meta}>
      <MenuOver />
      <IonPage id="main-content">
        <IonHeader>
          <section className="headerNavBarA">
            <div className="cssBack">
              <BtnBack />
            </div>
            <div>
              <a href={"/inicio"}>
                <img
                  className="logoMenuYappa"
                  alt="logo yappa"
                  src={
                    "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/9fc0ed25-63ad-4365-d2e4-6739bc7f2c00/public"
                  }
                />
              </a>
            </div>
            <div className="cssBottonMenu">
              <IonMenuButton></IonMenuButton>
            </div>
          </section>
        </IonHeader>

        <IonHeader>
          <IonToolbar>
            <a className="vinculoA" href={"/yappa_categoria/" + subcategoria}>
              <img
                className="imagenCategoriaYappa"
                src={icono}
                width={100}
                alt="icono de la categoria"
              ></img>
              <IonTitle className="tituloCategoria">
                {" "}
                {yappa[0]?.subcategoria}{" "}
              </IonTitle>
            </a>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard className="fondoPage">
            <section className="GridCard">
              <div className="ColCardIMG">
                <img className="imagenYappa" src={yappa[0]?.url}></img>
              </div>
              <div className="ColCardInformation">
                <IonCardContent>
                  <IonGrid>
                    <section className="tituloBtnLikeShare">
                      <div className="headerYappaInformacion">
                        <div>
                          <h6 className="nombreCategoria">
                            {yappa[0]?.subcategoria}
                          </h6>
                          <h4 className="nombreNegocio">{yappa[0]?.negocio}</h4>
                          <h2 className="nombreYappa">{yappa[0]?.nombre}</h2>
                        </div>
                        <div className="botoneslikeshare">
                          <BtnsLikeShare id_yappa={id.id} />
                        </div>
                      </div>
                      <div className="informacionYappaDetalles">
                        <p>
                          <b>Descripción:</b> {yappa[0]?.descripcion}{" "}
                        </p>
                        <p>
                          <b>Yappas disponibles: </b>{" "}
                          {yappa[0]?.cantidad_disponible < "Agotada"
                            ? 0
                            : yappa[0]?.cantidad_disponible}
                        </p>
                        <p>
                          <b>Vigencia: </b> Disponible hasta el{" "}
                          {yappa[0]?.fecha_publicacion.split(" ")[0]}
                        </p>
                        <p>
                          <b>Cuidad: </b> {yappa[0]?.ciudad}{" "}
                        </p>
                        <p>
                          <b>Dirección: </b> {yappa[0]?.lugar}
                        </p>
                        <p>
                          <b>Costo: </b> $ 60.00 {yappa[0]?.costo}
                        </p>
                        <p>
                          <b>Restricciones: </b> {yappa[0]?.restricciones}
                        </p>
                        <p className="enlace">
                          <b>Enlace: </b>{" "}
                          <a href={yappa[0]?.url_evento}>
                            {yappa[0]?.url_evento}
                          </a>
                        </p>
                      </div>
                    </section>
                    <section className="sectionBtnCanjear">
                      {
                        // eslint-disable-next-line no-restricted-globals
                      }
                      <BtnCanjearYappa
                        obj={yappa[0]}
                        whatsapp={`https://wa.me/593${
                          yappa[0]?.celular_sucursal
                        }?text=Quiero%20yappear:%20https://mi.yappa.ec/yappa/${location.href
                          .split("/")
                          .at(-1)}`}
                      />
                    </section>
                  </IonGrid>
                </IonCardContent>
              </div>
            </section>
          </IonCard>

          <>
            <section className="seccionSugerencias">
              <h2 className="tituloSugerencias"> Sugerencias </h2>
              <Sliders variableWidth={true} slidesToScroll={5} infinite={false}>
                {yappaRelacionada.map((value, index) => {
                  return (
                    <YappasRelacionadas
                      key={index}
                      categoria={value.subcategoria}
                      empresa={value.negocio}
                      yappa={value.nombre}
                      url={value.url}
                    ></YappasRelacionadas>
                  );
                })}
              </Sliders>
            </section>
          </>
        </IonContent>
      </IonPage>
    </DocumentMeta>
  );
};
