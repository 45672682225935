import React, { useEffect, useState } from "react";
import {
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import "./login.css";
import { BtnLoginGoogle } from "./BtnLoginGoogle";

export const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [presentAlert] = useIonAlert();
  const [present] = useIonLoading();

  const back = () => {
    let paths = sessionStorage.getItem("path");

    try {
      paths = paths.split(",");
      paths.at(-1);

      const url = paths.at(-1);
      let news_paths = "";

      paths.pop();

      paths.map((value, index) => 
        index === 0 ? (news_paths += `${value}`) : (news_paths += `,${value}`)
      );

      sessionStorage.setItem("path", `${news_paths}`);

      // eslint-disable-next-line no-restricted-globals
      location.href = url;
    } catch (e) {
      // eslint-disable-next-line no-restricted-globals
      location.href = paths;
    }
  };

  const sendInfo = async () => {
    const body = {
      email: email,
      clave: password,
    };

    if (email === "" && password === "") {
      presentAlert({
        header: "¡Ups! Debe llenar los campos para ingresar",
        cssClass: "MsgError",
        buttons: ["Ok"],
      });
    } else {
      const response = await fetch("https://mi.yappa.ec/api/ingreso", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      });
      const data = await response.json();

      if (data.mensaje === "clave incorrecta") {
        present({
          message: "Usuario o clave incorrectas",
          duration: 5000,
          cssClass: "MsgError",
          translucent: true,
          showBackdrop: false,
        });
      } else if (data.mensaje === "El usuario no existe") {
        present({
          message: "Lo sentimos, pero el email es incorrecto",
          duration: 5000,
          cssClass: "MsgError",
          translucent: true,
          showBackdrop: false,
        });
      } else {
        present({
          message: "Estamos chequeando tu información",
          duration: 5000,
          cssClass: "MsgTimer",
          translucent: true,
          showBackdrop: false,
        });
        try {
          const key = await data.access_token.split("|");

          sessionStorage.setItem("token", key.at(-1));
          sessionStorage.setItem("id", data.id);
          sessionStorage.setItem("remember", 0);
          sessionStorage.setItem("nombre", data.nombre);

          if (sessionStorage.save !== undefined) {
            const save = async (id_yappa) => {
              const body = {
                id_usuario: +sessionStorage.getItem("id"),
                id_yappa: id_yappa,
              };

              await fetch("https://mi.yappa.ec/api/guardar_yappa", {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  "Access-Control-Allow-Origin": "*",
                },
              });
            };
            save(+sessionStorage.save);
            setTimeout(() => {
              sessionStorage.save = undefined;
              try {
                back();
              } catch (error) {
                // eslint-disable-next-line no-restricted-globals
                location.href = "/misyappas";
              }
            }, 3000);
          } else {
            setTimeout(() => {
              // eslint-disable-next-line no-restricted-globals
              location.href = "";
            }, 2000);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  useEffect(() => {}, []);
  const redirect = (href) => {
    let path = sessionStorage.getItem("path");
    // eslint-disable-next-line no-restricted-globals
    path !== "" ? (path += `,${location.href}`) : (path += `${location.href}`);
    sessionStorage.setItem("path", `${path}`);
    // eslint-disable-next-line no-restricted-globals
    location.href = href;
  };

  return (
    <>
      <IonPage id="main-content" className="fondoLogin">
        <IonList className="listmenu">
          <div className="tituloFormulario">
            <IonLabel
              className="tituloForm"
              style={{cursor: "pointer"}}
              onClick={() => {
                redirect("/inicio");
              }}
            >
              {" "}
              ¡Vamos a Yappear!
            </IonLabel>
          </div>

          <section className="seccionInputs">
            <IonItem className="estiloInput">
              <IonLabel position="floating" style={{ paddingBottom: 6 }}>
                Usuario o E-mail
              </IonLabel>
              <IonInput
                placeholder="Ingrese su usuario o e-mail"
                onKeyUp={(event) => {
                  setEmail(event.currentTarget.value);
                }}
              ></IonInput>
            </IonItem>
            <IonItem className="estiloInput">
              <IonLabel position="floating" style={{ paddingBottom: 6 }}>
                Contraseña
              </IonLabel>
              <IonInput
                placeholder="Ingrese su contraseña"
                type="password"
                onKeyUp={(event) => {
                  setPassword(event.currentTarget.value);
                }}
              ></IonInput>
            </IonItem>
          </section>
          <section className="forgotPass">
            <button
              className="btnForgotPass"
              onClick={() => {
                redirect("/recuperacionContrasena");
              }}
            >
              <p>¿Olvidaste tu contraseña?</p>
            </button>
          </section>
          <section className="containerBotones">
            <section className="seccionBotones">
              <button
                className="btnLogin"
                onClick={() => {
                  sendInfo();
                }}
              >
                <p> Iniciar Sesión</p>
              </button>
            </section>

            <section className="seccionBtnCrearAccount">
              <p className="textoCrearCuenta">¿Aun no eres un yapeador?</p>
              <button
                className="crearCuenta"
                onClick={() => {
                  redirect("/registroUser");
                }}
              >
                <p>Crea una cuenta</p>
              </button>
            </section>

            <section className="seccionBotones">
              <BtnLoginGoogle />
            </section>
          </section>
        </IonList>
      </IonPage>
    </>
  );
};
