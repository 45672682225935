import { useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";

import { useIonAlert, useIonLoading } from "@ionic/react";

export const BtnLoginGoogle = () => {
  const [presentAlert] = useIonAlert();
  const [present] = useIonLoading();
  const clientID =
    "172823968608-8j6lmd05u26d7ktq9j9acfttns7u9do7.apps.googleusercontent.com";
  //seccion de google auth
  useEffect(() => {
    const start = () => {
      gapi.auth2.init({
        client_id: clientID,
      });
    };
    gapi.load("client:auth2", start);
  }, []);

  const back = () => {
    let paths = sessionStorage.getItem("path");

    try {
      paths = paths.split(",");
      paths.at(-1);

      const url = paths.at(-1);
      let news_paths = "";

      paths.pop();

      paths.map((value, index) =>
        index === 0 ? (news_paths += `${value}`) : (news_paths += `,${value}`)
      );

      sessionStorage.setItem("path", `${news_paths}`);

      // eslint-disable-next-line no-restricted-globals
      location.href = url;
    } catch (e) {
      // eslint-disable-next-line no-restricted-globals
      location.href = paths;
    }
  };

  const onSuccess = async (response) => {
    console.log(response);

    const body = {
      email: response?.profileObj?.email,
      clave: response?.profileObj?.googleId,
      clave_confirmation: response?.profileObj?.googleId,
      nombre_completo: response?.profileObj?.name,
      celular: "00000000000",
      direccion: "default",
    };
    // Registro del usuario

    present({
      message: "Estamos chequeando tu información",
      duration: 5000,
      cssClass: "MsgTimer",
      translucent: true,
      showBackdrop: false,
    });

    try {
      console.log("antes del fetch");
      await fetch("https://mi.yappa.ec/api/registro", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      });
    } catch {
      present({
        message: "¡Ups! Hubo un error con Google",
        duration: 5000,
        cssClass: "MsgError",
        translucent: true,
        showBackdrop: false,
      });
    }

    try {
      const user_info = {
        email: body.email,
        clave: body.clave,
      };

      const responses = await fetch("https://mi.yappa.ec/api/ingreso", {
        method: "POST",
        body: JSON.stringify(user_info),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      });

      const data = await responses.json();

      const key = await data.access_token.split("|");

      sessionStorage.setItem("token", key.at(-1));
      sessionStorage.setItem("id", data.id);
      sessionStorage.setItem("remember", 0);
      sessionStorage.setItem("nombre", data.nombre);
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      try {
        back();
      } catch (error) {
        // eslint-disable-next-line no-restricted-globals
        location.href = "/";
        console.log("Ingreso");
      }
    }, 1000);
  };

  const onFailure = () => {
    presentAlert({
      header: "Se genero un error en el login",
      cssClass: "MsgError",
      buttons: ["OK"],
    });
  };

  return (
    <section>
      <div>
        <GoogleLogin
          className="btnloginGoogle"
          clienteId={clientID}
          onSuccess={onSuccess}
          onError={onFailure}
          cookiesPolicy={"single_host_policy"}
        />
      </div>
    </section>
  );
};
