/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  useIonAlert,
} from "@ionic/react";
import { BuscadorGlobal } from "../../componentes/buscador/BuscadorGlobal";

import "./Home.css";
import SliderPautas from "../../componentes/sliderPautas/SliderPautas";
import { YappaCaliente } from "../../componentes/yappaCaliente/YappaCaliente";
// import { YappaRegular } from "../../componentes/yappaRegular/YappaRegular";
import { Category } from "../../componentes/categorias/Category";
import { useEffect } from "react";
import MenuOver from "../../componentes/header/MenuOver";
import { BtnBack } from "../../componentes/btnBack/BtnBack";

const Inicio = () => {
  const [presentAlert] = useIonAlert();

  const remember = async (id_user: any) => {
    if (+sessionStorage.getItem("remember")! === 0) {
      const body = {
        id_usuario: id_user,
      };

      const response = await fetch(
        "https://mi.yappa.ec/api/yappa_recordatorio_canje",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      const data = await response.json();

      sessionStorage.remember = 1;

      if ((await data.original.length) > 0) {
        presentAlert({
          header: "¡Tienes Yappas prontas a expirar! ¡Canjealas! :)",
          buttons: [
            {
              text: "Ir a canjearlas",
              handler: () => {
                // eslint-disable-next-line no-restricted-globals
                location.href = "/misyappas";
              },
            },
            "Ahora no",
          ],
        });
      }
    }
  };

  useEffect(() => {
    sessionStorage.setItem("path", "");

    if (
      sessionStorage.getItem("id") !== "null" &&
      sessionStorage.getItem("id") != null &&
      sessionStorage.getItem("id") !== undefined
    ) {
      remember(sessionStorage.getItem("id"));
    }
  }, []);

  return (
    <>
      <MenuOver />
      <IonPage id="main-content">
        <IonHeader>
          <section className="headerNavBarA">
            <div className="cssBack">
              <BtnBack />
            </div>
            <div>
              <img
                className="logoMenuYappa"
                alt="logo yappa"
                src={
                  "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/9fc0ed25-63ad-4365-d2e4-6739bc7f2c00/public"
                }
              />
            </div>
            <div className="cssBottonMenu">
              <IonMenuButton></IonMenuButton>
            </div>
          </section>
        </IonHeader>

        <IonContent className="ion-padding">
          <SliderPautas />
          <BuscadorGlobal />
          <Category />
          <div className="colum-1">
            <YappaCaliente />
          </div>
          {/* <div className="colum-1">
            <YappaRegular />
          </div> */}
        </IonContent>
      </IonPage>
      <a href="/TerminosCondiciones" style={{color: "transparent"}}>Términos y condiciones</a>
    </>
  );
};

export default Inicio;
