/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonPage,
  useIonAlert,
  IonContent,
  IonHeader,
  IonMenuButton,
} from "@ionic/react";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import YappaCard from "../../componentes/tarjetas/YappaCard";
import "../yappaCategoria/yappaCategoria.css";
import MenuOver from "../../componentes/header/MenuOver";
import { BtnBack } from "../../componentes/btnBack/BtnBack";

export const YappaCategoria = () => {
  const slug = useParams();
  const [presentAlert] = useIonAlert();
  const [category, setCategory] = useState("");
  const [yappa, setYappa] = useState([]);
  const [icono, setIcono] = useState([
    "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/9fc0ed25-63ad-4365-d2e4-6739bc7f2c00/public",
  ]);
  const [banner, setBanner] = useState([
    "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/d10c92f4-21b3-42bf-e817-2196f196f300/public",
  ]);

  const obtener_imagen_categoria = async () => {
    const id_subcategoria = location.href.split("/");
    const body = {
      subcategoria: id_subcategoria.at(-1),
    };

    if (body.subcategoria.search(/Viajes/) >= 0) {
      body.subcategoria = body.subcategoria.replaceAll("-", " / ");
    }

    const response = await fetch(
      "https://mi.yappa.ec/api/yappa_busqueda_nombre_categoria",
      {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );

    const data = await response.json();
    setIcono(data[0].url_img);
    setBanner(data[0].url_banner);
  };

  const buscar_yappa_categoria = async () => {
    const id_subcategoria = location.href.split("/");
    const body = {
      subcategoria: id_subcategoria.at(-1),
    };

    if (body.subcategoria.search(/Viajes/) >= 0) {
      body.subcategoria = body.subcategoria.replaceAll("-", " / ");
    }

    const response = await fetch(
      "https://mi.yappa.ec/api/yappa_busqueda_nombre_categoria",
      {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );

    const data = await response.json();
    if ((await data.length) > 0) {
      setCategory(data[0].subcategoria);
      setYappa(data);
    } else {
      setYappa([
        {
          titulo: "Lo sentimos, no hay Yappas en esta categoria",
          url: "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/a6999688-b9f4-48cf-5f3f-9b13e5c33e00/public",
          descripcion: "Lo sentimos, no hay Yappas en esta categoria",
        },
      ]);
      presentAlert({
        header: "Lo sentimos, no hay Yappas en esta categoria",
      });
    }
  };

  useEffect(() => {
    buscar_yappa_categoria(slug);
    obtener_imagen_categoria();
  }, [slug]);

  return (
    <>
      <MenuOver />
      <IonPage id="main-content">
        <IonHeader>
          <section className="headerNavBarA">
            <div className="cssBack">
              <BtnBack />
            </div>
            <div>
              <a href={"/inicio"}>
                <img
                  className="logoMenuYappa"
                  alt="logo yappa"
                  src={
                    "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/9fc0ed25-63ad-4365-d2e4-6739bc7f2c00/public"
                  }
                />
              </a>
            </div>
            <div className="cssBottonMenu">
              <IonMenuButton></IonMenuButton>
            </div>
          </section>

          <section className="bannerCategoria">
            <div>
              <img
                className="imagenCategoriaDestacada"
                src={banner}
                alt="banner yappa popular"
              />
            </div>
          </section>
          <section className="navInformation">
            <div>
              <img
                className="imagenCategoria"
                src={icono}
                alt="icono de la categoria"
              ></img>
            </div>
            <div>
              <h3 className="tituloCategoria">{category}</h3>
            </div>
          </section>
        </IonHeader>

        <IonContent className="ion-padding">
          <section className="gridCard">
            {yappa.map((value, index) => {
              return (
                <YappaCard
                  key={index}
                  id={value.id}
                  titulo={value.nombre}
                  url={value.url}
                  id_yappa={value.id}
                  categoria={category}
                  negocio={value.negocio}
                  fecha_publicacion={value.fecha_publicacion}
                  fecha_expiracion={value.fecha_expiracion}
                  cantidadYappas={value.cantidad}
                />
              );
            })}
          </section>
        </IonContent>
      </IonPage>
    </>
  );
};
